import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withKeys as _withKeys, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0d24c51b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "edit-email"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "trip"
};
const _hoisted_4 = {
  class: "action-btn"
};
import PageTitle from '@/components/page-title';
import { reactive, ref, watch, onUnmounted } from 'vue';
import { isEmailFormat, reg_email } from '@/utils/validateFormate.js';
import useUserStore from '@/store/modules/user.js';
import { editEmail, sendECodeEmail } from '@/service/setting/email.js';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'bind-email',

  setup(__props) {
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    const formRef = ref(null);
    const emailForm = reactive({
      currEmail: userStore.userInfo.email,
      pass: '',
      newEmail: '',
      captcha_text: ''
    });
    const emailFormat = ref(true);

    const handleSaveEmailClick = () => {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          handleSaveEmail();
        }
      });
    }; // 发送验证码


    const sendText = ref(t('email.send_code'));
    const isSend = ref(false);
    let totalTime = 60;
    let timer = '';

    const handleReVerifyEmail = () => {
      if (isSend.value) return; // 发送验证码请求

      const formData = new FormData();
      formData.append('email', emailForm.newEmail);
      formData.append('password', emailForm.pass);
      sendECodeEmail(formData).then(res => {
        if (res.code == 200) {
          // 发送验证码成功，请留意新邮箱中的邮件
          ElMessage({
            message: t('email.send_code_hint'),
            type: 'success'
          });
          isSend.value = true; // s后可重新发送

          sendText.value = t('register.later_send_head') + totalTime + t('register.later_send');
          timer = setInterval(() => {
            totalTime = totalTime - 1; // s后可重新发送

            sendText.value = t('register.later_send_head') + totalTime + t('register.later_send');

            if (totalTime < 0) {
              clearInterval(timer); // 发送验证码

              sendText.value = t('email.send_code');
              totalTime = 60;
              isSend.value = false;
            }
          }, 1000);
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    };

    onUnmounted(() => {
      if (timer) clearInterval(timer);
    });

    const handleSaveEmail = () => {
      const data = {
        code: emailForm.captcha_text,
        email: emailForm.newEmail,
        password: emailForm.pass
      };
      editEmail(data).then(res => {
        if (res.code == 200) {
          // 修改邮箱成功
          ElMessage({
            message: t('email.save_success'),
            type: 'success'
          }); // 清楚格式校验

          formRef.value.resetFields(); // 清楚定时器

          if (timer) clearInterval(timer); // 发送验证码

          sendText.value = t('email.send_code');
          totalTime = 60;
          isSend.value = false; // 跟新userSote中的userInfo

          userStore.updateUserInfo({
            email: data.email,
            user_id: userStore.userInfo.user_id,
            username: userStore.userInfo.username
          });
          emailForm.currEmail = data.email;
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    }; // 输入密码时才显示验证码


    watch(() => emailForm.newEmail, newVal => {
      if (newVal) {
        if (isEmailFormat(newVal)) emailFormat.value = false;else emailFormat.value = true;
      }
    });
    const formRule = {
      pass: [{
        required: true,
        message: t('register.form_rules.password.required'),
        trigger: ['blur', 'change']
      }],
      newEmail: [{
        required: true,
        message: t('register.form_rules.email.required'),
        trigger: ['blur', 'change']
      }, // 邮箱格式错误
      {
        pattern: reg_email,
        message: t('register.form_rules.email.pattern'),
        trigger: ['blur']
      }],
      captcha_text: [{
        required: true,
        message: t('register.form_rules.captcha_text.required'),
        trigger: ['blur', 'change']
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(PageTitle), {
        title: _ctx.$t('settingMain.menu_list.bind_email')
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: emailForm,
        "label-position": "top",
        "label-width": "100px",
        "hide-required-asterisk": "",
        style: {
          "width": "300px"
        },
        rules: formRule,
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: _ctx.$t('email.curr_email')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: emailForm.currEmail,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => emailForm.currEmail = $event),
            disabled: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          label: _ctx.$t('email.password'),
          prop: "pass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: emailForm.pass,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => emailForm.pass = $event),
            autocomplete: "off",
            type: "password",
            "show-password": "",
            placeholder: _ctx.$t('email.hint_text.password_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          label: _ctx.$t('email.new_email'),
          prop: "newEmail"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: emailForm.newEmail,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => emailForm.newEmail = $event),
            autocomplete: "off",
            placeholder: _ctx.$t('email.hint_text.new_email_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          label: _ctx.$t('email.email_verify_code'),
          prop: "captcha_text"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: emailForm.captcha_text,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => emailForm.captcha_text = $event),
                placeholder: _ctx.$t('email.hint_text.code_hint'),
                onKeydown: _withKeys(handleSaveEmailClick, ["enter"])
              }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                class: "send-btn",
                disabled: isSend.value || emailFormat.value,
                onClick: handleReVerifyEmail
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(sendText.value), 1)]),
                _: 1
              }, 8, ["disabled"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["model"]), _createElementVNode("ul", _hoisted_3, [_createElementVNode("li", null, _toDisplayString(_ctx.$t('email.hint_text.text_hint_head')), 1), _createElementVNode("li", null, _toDisplayString(_ctx.$t('email.hint_text.text_hint_tail')), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleSaveEmailClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('email.save_btn')), 1)]),
        _: 1
      })])])]);
    };
  }

};