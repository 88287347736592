import request from '@/service/index'

const EditEmailApi = {
  editEmail: '/portal/setting/email',
  sendECodeEmail: '/portal/setting/email'
}

export function editEmail(data) {
  return request.put({
    url: EditEmailApi.editEmail,
    data
  })
}

export function sendECodeEmail(data) {
  return request.post({
    url: EditEmailApi.sendECodeEmail,
    data
  })
}
